<template lang="pug">
include ../../../configs/template
div.pa-4
  v-card-text
    div
      +select-validation('body.type_receipt','listProcessingStatuses','receiveDoc','nameLang','["required"]')
    div
      +select-validation('body.port','listPort','port','nameLang','["required"]')
    div(v-if="[TYPE_SAILOR_PASSPORT_PROCESSING_STATUS.CONTINUED_IN_20_DAYS, TYPE_SAILOR_PASSPORT_PROCESSING_STATUS.CONTINUED_IN_7_DAYS].includes(body.type_receipt)")
      +select-validation('body.sailor_passport','listBooks','sailorPassport','"number_document"','["required"]')
        template(#item="{ item }")
          div № {{item.number_document}} - {{ getDirectoryObject({ id: item.port, value: 'ports' })[nameLang] }}
  v-card-text
    FileDropZone(ref="mediaContent").w-100
  v-card-actions.justify-center
    v-btn(color="success" @click="saveStatement" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { sailor } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { TYPE_SAILOR_PASSPORT_PROCESSING_STATUS } from '@/configs/constants'
import { clearBody } from '@/mixins/main'
const initBody = () => (
  {
    type_receipt: null,
    port: null,
    sailor_passport: null
  }
)
export default {
  name: 'SailorPassportForm',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      TYPE_SAILOR_PASSPORT_PROCESSING_STATUS,
      body: initBody()
    }
  },
  computed: {
    ...mapState({
      listProcessingStatuses: state => state.directory.processingStatuses.filter(el => {
        return el.id !== TYPE_SAILOR_PASSPORT_PROCESSING_STATUS.DO_NOT_NEED
      }),
      listPort: state => state.directory.ports,
      isLoading: state => state.sailor.isLoading,
      listSailorPassport: state => state.sailor.existSailorPassports,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    listBooks () {
      return this.listSailorPassport.filter(el => el.port === this.body.port)
    }
  },
  validations () { return { body: sailor.statement.sailorPassport() } },
  methods: {
    ...mapActions(['setSailorPassportStatement']),
    async saveStatement () {
      this.body.sailor = this.$route.params.id
      if (this.$vnode.$invalid) return this.$vnode.$touch()
      const response = await this.setSailorPassportStatement(
        {
          ...this.$route.params,
          body: clearBody(this.body),
          media: {
            file_type: 'statement_sailor_passport',
            files: [...this.$refs.mediaContent.filesArray]
          }
        }
      )
      if (response) {
        this.$parent.isViewAddSlot = false
        this.$data.dataForm = initBody()
        this.$v.$reset()
      }
    }
  }
}
</script>
